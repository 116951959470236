export default function CancellableAsyncViewProxy(_callback) {
    var self = null;
    var container = null;
    var req = null;

    var callback = {
        success: function(proxy) {
        },
        failure: function(proxy, error) {
        },
        prepare: function(proxy, info) {
            return info;
        }
    };

    this.getCallback = function() {
        return callback;
    };

    this.setCallback = function(c) {
        callback = c;
    };

    this.setContainer = function(c) {
        container = c;
    };

    this.getContainer = function() {
        return container;
    };

    this.prepare = function() {
        return callback.prepare(self, {});
    };

    this.execute = function() {
        var info = self.prepare();

        if (req != null) {
            req.abort();
            req = null;
        }

        req = $.ajax
        ({
            url: info.url,
            type: "GET",
            data: info.data,
            processData: true,
            success: function() {
                callback.success(self)
            },
            error: function(event) {
                callback.failure(self, event);
            }
        });

        return req;
    };

    this.abort = function() {
        if (req != null) {
            req.abort();
            req = null;
        }
    };

    var __construct = function (that) {
        self = that;

        callback = _callback;
    }(this)
}