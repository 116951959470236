export default {
    installStringFormat() {
        if (!String.prototype.format) {
            String.prototype.format = function() {
                var str = this.toString();
                if (!arguments.length)
                    return str;
                var args = typeof arguments[0],
                    args = (("string" == args || "number" == args) ? arguments : arguments[0]);
                for (let arg in args)
                    str = str.replace(RegExp("\\{" + arg + "\\}", "gi"), args[arg]);
                return str;
            }
        }

        if (!String.prototype.isEmpty) {
            String.prototype.isEmpty = function () {
                return (this.length === 0 || !this.trim());
            };
        }
    },
    installMoneyParser() {
        if (!Number.prototype.format) {
            Number.prototype.format = function(n, x, s, c) {
                var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
                    num = this.toFixed(Math.max(0, ~~n));

                return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
            };
        }

        if (!String.prototype.fromMoney) {
            String.prototype.fromMoney = function () {
                var newValue = this.replace(/\./g, '').replace(/,/g, '.') * 1.0;

                return newValue;
            };
        }

        if (!String.prototype.toMoney) {
            String.prototype.toMoney = function() {
                var newValue = this.replace(/,/g, '.')*1.0;

                return newValue.format(2,3,'.',',');
            };
        }
    }
};
