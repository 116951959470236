export default {
    fitAspect(elements, x, y, byWidth) {
        if ($(elements).size == 0)
            return;

        let width = $($(elements)[0]).width();
        let height = $($(elements)[0]).height();

        if (height > width && !byWidth) {
            let w = (x/(y*1.0))*height;

            $(elements).width(w);
        } else {
            let h = (x/(y*1.0))*width;

            $(elements).height(h);
        }
    },
    fitByHeight(targets, aspect) {
        let es = $(targets);
        let h = $(targets).height();

        es.each(function() {
            let e = $(this);

            e.width(h*aspect);
        });
    },
    fitByWidth(targets, aspect) {
        let es = $(targets);
        let w = $(targets).width();

        es.each(function() {
            let e = $(this);

            e.height(w*aspect);
        });
    },
    resizeFullElements() {
        let containers = $(".full");

        containers.each(function() {
            let self = $(this);
            let factor = 1.0;

            if (self.data("factor") != null)
                factor = self.data("factor")*factor;

            self.css("min-height", factor*$(window).height());
        });

        containers.each(function() {
            let self = $(this);
            let belowSelector = self.data("below");
            let spacing = self.data("spacing");

            if (typeof(belowSelector) != 'undefined') {
                let below = $(belowSelector);

                let belowHeight = below.css("min-height").replace("px","");
                let selfHeight = self.css("min-height").replace("px","");
                /* FIXME: supondo que o height seja equivalente ao min-height */

                below.css("min-height", belowHeight*(1.0 - spacing));
                self.css("margin-top", belowHeight*spacing);
            }
        });

        $(".full.sunset").each(function() {
            let self = $(this);
            let w = self.width();
            let bkg = self.find(".background");
            let bkgBorderWidth = 5;
            let factor = bkg.data("background-factor");

            if (typeof(factor) == 'undefined')
                factor = 1.5;
            else
                factor = factor*1.0;

            bkg.width(w*factor);
            bkg.height(w*factor);
            bkg.css("left", (w*(1.0 - factor) - bkgBorderWidth*2.0)/2.0);

            bkg.each(function() {
                let b = $(this);
                if (b.hasClass("bottom") && (self.width()*factor > self.height())) {
                    b.hide();
                } else {
                    b.show();
                }
            });
        });
    }
};
