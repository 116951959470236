export default {
    installRemoveItem() {
        if (!Array.prototype.removeItem) {
            Object.defineProperty(Array.prototype, "removeItem", {
                enumerable: false,
                value: function (itemToRemove) {
                    var removeCounter = 0;

                    for (var index = 0; index < this.length; index++) {
                        if (this[index] === itemToRemove) {
                            this.splice(index, 1);

                            removeCounter++;

                            index--;
                        }
                    }

                    return removeCounter;
                }
            });
        }
    }
};
