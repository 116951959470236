export function HolderBuilder(w) {
    var self = null;
    var wrapped = null;

    this.build = function(holder) {
        var cta = $("<div class='btn-holder columns small-12'><a class='cta btn'></a></div>");
        var btn = cta.find(".cta.btn");
        btn.addClass(holder.getActionCustomClass());
        btn.text(holder.getActionLabel());

        if (holder.getActionExecutor != null)
            btn.click(holder.getActionExecutor());

        if (holder.getActionLink() != "")
            btn.attr("href", holder.getActionLink());

        if (holder.getActionEnabled()) {
            holder.getElement().append(cta);
        }

        if (wrapped != null) {
            return wrapped.build(holder);
        }

        return $(holder.getContainerSelector());
    }

    var __construct = function (that) {
        self = that;
        wrapped = w;
    }(this)
}

export default function ContainerBuilder(w) {
    var self = null;
    var wrapped = null;

    this.getParams = function() {
        return wrapped.getParams();
    }

    this.build = function(container, ele) {
        if (wrapped != null) {
            return wrapped.build(container, ele);
        }

        return null;
    }

    this.configure = function(container, ele) {
        if (wrapped != null)
            return wrapped.configure(container, ele);

        return ele;
    }

    var __construct = function (that) {
        self = that;
        wrapped = w;
    }(this)
}
