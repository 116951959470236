import ContainerBuilder from './builders/base';

export default function ContainerComponent(params) {
    var self = null;
    var identifier = null;
    var proxy = null;
    var holder = null;
    var elem = null;
    var builder = null;
    var binder = null;
    var data = {};

    var emptyOrFailureHolder = null;
    var pendingHolder = null;
    var contentHolder = null;

    var containerSelector = null;
    var contentSelector = null;
    var loaderColor = "#fff";

    var actionEnabled = false;
    var actionCustomClass = "";
    var actionExecutor = null;
    var actionLink = "";

    var isBindingEnabled = false;
    var contentAlreadyLoaded = false;
    var loading = true;
    var title = null;
    var messages = {
        pending: "coletando informações..",
        empty: "não existem informações :/",
        failure: "não foi possível obter as informações"
    };

    var listeners = [];
    var bindings = [];

    this.getData = function() {
        return data;
    }

    this.getUniqueIdentifier = function() {
        return self.getHolder().getContainerSelector()
            + " "
            + proxy.getContainer().getContainerSelector()
            + " "
            + proxy.getContainer().getContentSelector();
    }

    this.getActionLink = function() {
        return actionLink;
    }

    this.getActionExecutor = function() {
        return actionExecutor;
    }

    this.getActionCustomClass = function() {
        return actionCustomClass;
    }

    this.getActionEnabled = function() {
        return actionEnabled;
    }

    this.setPendingMessage = function(pm) {
        messages.pending = pm;

        if (pendingHolder != null)
            pendingHolder.find(".value").html(messages.pending);
    }

    this.setEmptyMessage = function(em) {
        messages.empty = em;

        if (emptyOrFailureHolder != null)
            emptyOrFailureHolder.find(".value").html(messages.empty);
    }

    this.setFailureMessage = function(fm) {
        messages.failure = fm;
    }

    this.getMessages = function() {
        return messages;
    }

    this.setTitle = function(t) {
        title = t;

        updateTitle(elem);
    }

    this.getTitle = function() {
        return title;
    }

    this.getIdentifier = function() {
        return identifier;
    }

    this.setIdentifier = function(i) {
        identifier = i;
    }

    this.setEnableBinding = function(should) {
        isBindingEnabled = should;

        /* TODO: avisar entidades? */
    }

    this.addBinding = function(container) {
        bindings.push(container);

        if (isBindingEnabled && holder != null)
            container.setHolder(holder);

        container.setBinder(self);
    }

    this.removeBinding = function(container) {
        bindings.removeItem(container);

        if (isBindingEnabled && holder != null)
            container.setHolder(null);

        container.setBinder(null);
    }

    this.getBinder = function() {
        return binder;
    }

    this.setBinder = function(b) {
        binder = b;
    }

    this.addListener = function(listener) {
        listeners.push(listener);
    }

    this.removeListener = function(listener) {
        listeners.removeItem(listener);
    }

    this.getHolder = function() {
        return holder;
    }

    this.setHolder = function(h) {
        holder = h;

        listeners.forEach(function(listener) {
            listener.onAttach(self);
        });

        if (isBindingEnabled)
            bindings.forEach(function(container) {
                h.addContainer(container);
            });
    }

    this.getProxy = function() {
        return proxy;
    }

    this.getElement = function() {
        return elem;
    }

    var isEmpty = true;

    this.getEmpty = function() {
        return isEmpty;
    }

    this.setEmpty = function(e) {
        isEmpty = e;

        if (isEmpty) {
            emptyOrFailureHolder.show();
        } else {
            emptyOrFailureHolder.hide();
        }
    }

    this.clear = function() {
        contentHolder.html("");

        if (isBindingEnabled)
            bindings.forEach(function(container) {
                container.clear();
            });
    }

    this.isAlreadyLoaded = function() {
        return contentAlreadyLoaded;
    }

    this.setAlreadyLoaded = function(al) {
        contentAlreadyLoaded = al;
    }

    this.isLoading = function() {
        return loading;
    }

    this.setLoading = function(isLoading) {
        loading = isLoading;

        var shouldHideWhenLoading = [contentHolder, emptyOrFailureHolder];

        if (isBindingEnabled)
            bindings.forEach(function(container) {
                shouldHideWhenLoading.push(container.getElement());
                container.setLoading(false);
            });

        if (isLoading) {
            pendingHolder.show();
            shouldHideWhenLoading.forEach(function(element) {
                element.hide();
            });
        } else {
            pendingHolder.hide();
            shouldHideWhenLoading.forEach(function(element) {
                element.show();
            });

            self.setEmpty(self.getEmpty());
        }
    }

    this.setBuilder = function(b) {
        builder = b;
    }

    this.getBuilder = function() {
        return builder;
    }

    this.getContentSelector = function() {
        return contentSelector;
    }

    this.getContainerSelector = function() {
        return containerSelector;
    }

    this.setContainerSelector = function(cs) {
        containerSelector = cs;

        elem = $(containerSelector);
    }

    this.setContentSelector = function(cs) {
        contentSelector = cs;

        contentHolder = elem.find(contentSelector);
    }

    this.setActionEnabled = function(enabled) {
        actionEnabled = enabled;

        updateTitle(self.getElement());
    }

    this.setActionLink = function(link) {
        actionLink = link;

        if (actionEnabled)
            updateTitle(self.getElement());
    }

    this.create = function() {
        elem = builder.build(self);

        emptyOrFailureHolder = elem.find(".empty");
        pendingHolder = elem.find(".pending");
        contentHolder = elem.find(contentSelector);

        updateTitle(elem);

        emptyOrFailureHolder.find(".value").html(messages.empty);
        pendingHolder.find(".value").html(messages.pending);
        elem.data("component", self);

        if (isBindingEnabled)
            bindings.forEach(function(container) {
                container.create();
            });

        self.setLoading(true);

        listeners.forEach(function(listener) {
            listener.onCreate(self);
        });
    }

    this.initialize = function() {
        listeners.forEach(function(listener) {
            listener.onInit(self);
        });

        elem.data("component", self);
    }

    this.didLoad = function() {
        listeners.forEach(function(listener) {
            if (typeof(listener.didLoad) != 'undefined')
                listener.didLoad(self);
        });
    }

    this.didUpdate = function() {
        listeners.forEach(function(listener) {
            if (typeof(listener.didUpdate) != 'undefined')
            listener.didUpdate(self);
        });
    }

    this.didMove = function() {
        listeners.forEach(function(listener) {
            if (typeof(listener.didMove) != 'undefined')
            listener.didMove(self);
        });
    }

    var updateTitle = function(elem) {
        var sectionTitle = elem.find(".section_title");
        if (title != null) {
            sectionTitle.show();
            sectionTitle.html(title);
            if (actionEnabled)
                sectionTitle.addClass("action_enabled");

            sectionTitle.addClass(actionCustomClass);
            if (actionEnabled) {
                sectionTitle.off('click');
                sectionTitle.click(function() {
                    if (actionLink) {
                        goForwardSafely(actionLink);
                    }

                    if (actionExecutor) {
                        actionExecutor(self, sectionTitle);
                    }
                });
            }
        } else {
            sectionTitle.hide();
        }
    }

    var interceptBuilder = function(b) {
        return new ContainerBuilder({
            build: function(container, ele) {
                return b.build(container, ele);
            },
            configure: function(container, ele) {
                if (isBindingEnabled)
                    bindings.forEach(function(container) {
                        container.getBuilder().configure(container, container.getElement());
                    });

                return b.configure(container, ele);
            },
            getParams: function() {
                return b.getParams();
            }
        });
    }

    var intercept = function(p) {
        var callback = p.getCallback();

        p.setContainer(self);

        p.setCallback({
            success: function(proxy) {
                if (isBindingEnabled)
                    bindings.forEach(function(container) {
                        container.getProxy().getCallback().success(container.getProxy());
                    });

                callback.success(proxy);
            },
            failure: function(proxy, error) {
                if (isBindingEnabled)
                    bindings.forEach(function(container) {
                        container.getProxy().getCallback().failure(container.getProxy(), error);
                    });

                callback.failure(proxy, error);
            },
            prepare: function(proxy, info) {
                self.setLoading(true);

                callback.prepare(proxy, info);

                if (isBindingEnabled)
                    bindings.forEach(function(container) {
                        container.getProxy().getCallback().prepare(container.getProxy(), info);
                    });

                return info;
            }
        });

        return p;
    }

    var __construct = function (that) {
        self = that;
        identifier = params.identifier;
        proxy = intercept(params.proxy);
        builder = interceptBuilder(params.builder);
        self.setContainerSelector(params.containerSelector);
        self.setContentSelector(params.contentSelector);

        if (typeof(params.actionEnabled) != 'undefined')
            actionEnabled = params.actionEnabled;

        if (typeof(params.actionCustomClass) != 'undefined')
            actionCustomClass = params.actionCustomClass;

        if (typeof(params.actionLink) != 'undefined')
            actionLink = params.actionLink;

        if (typeof(params.actionExecutor) != 'undefined')
            actionExecutor = params.actionExecutor;
    }(this)
}