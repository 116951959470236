export default {
    isEmpty(str) {
        return (!str || /^\s*$/.test(str));
    },
    goBackSafely() {
        var current = window.location.href;

        if (typeof(document.referrer) == 'undefined' || document.referrer == "" || document.referrer == current || window.history.length <= 1) {
            window.location.replace("/");
        } else {
            window.history.back();
        }
    },
    goForwardSafely(url) {
        window.history.pushState(null, $('title').text(), window.location.href);
        window.location.replace(url);
    },
    goParallelSafely(url) {
        window.open(url, '_blank');
    }
};
