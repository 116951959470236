export default function LoadingWidget(widgetSelector, dependentSelector) {
    let widget = null;
    let dependents = [];
    let listeners = [];
    let loading = null;

    this.addListener = function(l) {
        listeners.push(l);
    }

    this.removeListener = function(l) {
        listeners.removeItem(l);
    }

    this.addDependent = function(d) {
        dependents.push(d);
    }

    this.removeDependent = function(d) {
        dependents.removeItem(d);
    }

    this.isLoading = function() {
        return loading;
    }

    this.setLoading = function(l) {
        loading = l;

        if (loading) {
            show();
        } else {
            hide();
        }

        listeners.forEach(function(elem) {
            elem(loading);
        });
    }

    let show = function() {
        dependents.forEach(function(elem) {
            elem.hide('fast');
        });

        widget.show('fast');
    }

    let hide = function() {
        dependents.forEach(function(elem) {
            elem.show('fast');
        });

        widget.hide('fast');
    }

    let __construct = function(that) {
        widget = $(widgetSelector);

        let deps = $(dependentSelector);
        deps.each(function() {
            that.addDependent($(this));
        });

        that.setLoading(false);
    }(this)
}