export function AsyncSenderProxy(_callback) {
    var self = null;
    var container = null;

    var callback = {
        success: function(proxy) {
        },
        failure: function(proxy, error) {
        },
        prepare: function(proxy, info) {
            return info;
        }
    };

    this.getCallback = function() {
        return callback;
    }

    this.setCallback = function(c) {
        callback = c;
    }

    this.setContainer = function(c) {
        container = c;
    }

    this.getContainer = function() {
        return container;
    }

    this.prepare = function() {
        return callback.prepare(self, {});
    }

    this.execute = function(waiter) {
        var info = self.prepare();

        $.ajax
        ({
            url: info.url,
            method: !!info.method ? info.method : "POST",
            data: info.data,
            processData: true,
            success: function() {
                callback.success(self, event);
                waiter.success(self, event);
            },
            error: function(event) {
                callback.failure(self, event);
                waiter.failure(self, event);
            }
        });

        return true;
    }

    var __construct = function (that) {
        self = that;

        callback = _callback;
    }(this)
}

export default function AsyncViewProxy(_callback) {
    var self = null;
    var container = null;

    var callback = {
        success: function(proxy) {
        },
        failure: function(proxy, error) {
        },
        prepare: function(proxy, info) {
            return info;
        }
    };

    this.getCallback = function() {
        return callback;
    }

    this.setCallback = function(c) {
        callback = c;
    }

    this.setContainer = function(c) {
        container = c;
    }

    this.getContainer = function() {
        return container;
    }

    this.prepare = function() {
        return callback.prepare(self, {});
    }

    this.execute = function() {
        var info = self.prepare();

        $.ajax
        ({
            url: info.url,
            type: "GET",
            data: info.data,
            processData: true,
            success: function() {
                callback.success(self)
            },
            error: function(event) {
                callback.failure(self, "errou");
            }
        });

        return true;
    }

    var __construct = function (that) {
        self = that;

        callback = _callback;
    }(this)
}
