export default function EmptyContainerBuilder(w, params) {
    var self = null;
    var wrapped = null;

    var parameters = {
    };

    this.getParams = function() {
        return parameters;
    };

    this.build = function(container, ele) {
        var containerSelector = container.getContainerSelector();

        if (ele == null || typeof(ele) == 'undefined')
            ele = $("<div class=''></div>");

        if (containerSelector.search("#") >= 0) {
            ele.attr("id", containerSelector.replace("#", ""));
        } else if (containerSelector.search(".") >= 0) {
            var splitted = containerSelector.split(".");
            splitted.forEach(function(word) {
                ele.addClass(word.replace(" ", ""));
            });
        }

        var title = $("<div class='section_title'></div>");

        var loader = createLoader(container);

        var pending = $("<div class='pending section_pending'></div>");
        pending.append($("<div class='value section_message'></div>"));
        pending.append(loader);

        var empty = $("<div class='empty section_empty'></div>");
        empty.append($("<div class='value section_message'></div>"));

        var content = $("<div class='section_content'></div>");
        content.data("parent", containerSelector);

        ele.append(title);
        ele.append(pending);
        ele.append(empty);
        ele.append(content);

        if (wrapped != null)
            return wrapped.build(container, ele);

        return ele;
    };

    this.configure = function(container, ele) {
        ele.each(function() {
            container.setLoading(false);
            container.setAlreadyLoaded(true);
            container.didLoad(container);

            $(window).resize(function() {
                container.didUpdate();
            });
        });

        if (wrapped != null)
            return wrapped.configure(container, ele);

        return ele;
    };

    var createLoader = function(container) {
        var loaderScript = "<div class='loading-holder'>"
            + "<div class='preloader-wrapper small active'>"
            + "<div class='spinner-layer'>"
            + "<div class='circle-clipper left'>"
            + "<div class='circle'></div>"
            + "</div>"
            + "<div class='gap-patch'>"
            + "<div class='circle'></div>"
            + "</div>"
            + "<div class='circle-clipper right'>"
            + "<div class='circle'></div>"
            + "</div>"
            + "</div>"
            + "</div>"
            + "</div>";

        var loader = $(loaderScript);

        loader.find(".circle-clipper").css(
            "border-color",
            "#fff"
        );

        return loader;
    };

    var __construct = function (that) {
        self = that;
        wrapped = w;

        if (params != null)
            parameters = params;
    }(this);
}
