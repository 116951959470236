export default function HolderComponent(params) {
    var self = null;
    var identifier = null;
    var elem = null;
    var builder = null;

    var title = null;
    var data = {};

    var containerSelector = null;
    var contentSelector = null;
    var contentHolder = null;
    var containerIdentifier = "holder_container";

    var actionEnabled = false;
    var actionLabel = "VER MAIS";
    var actionCustomClass = "";
    var actionExecutor = null;
    var actionLink = "";

    var listeners = [];
    var containers = [];

    this.getActionLink = function() {
        return actionLink;
    }

    this.getActionExecutor = function() {
        return actionExecutor;
    }

    this.getActionLabel = function() {
        return actionLabel;
    }

    this.getActionCustomClass = function() {
        return actionCustomClass;
    }

    this.getActionEnabled = function() {
        return actionEnabled;
    }

    this.getData = function() {
        return data;
    }

    this.setTitle = function(t) {
        title = t;

        updateTitle(elem);
    }

    this.getTitle = function() {
        return title;
    }

    this.getIdentifier = function() {
        return identifier;
    }

    this.setIdentifier = function(i) {
        identifier = i;
    }

    this.addListener = function(listener) {
        listeners.push(listener);
    }

    this.removeListener = function(listener) {
        listeners.removeItem(listener);
    }

    this.addContainer = function(container) {
        containers.push(container);

        contentHolder.append(container.getElement());
        container.getElement().addClass(containerIdentifier);
        container.setHolder(self);
    }

    this.removeContainer = function(container) {
        containers.removeItem(container);
        container.getElement().removeClass(containerIdentifier);

        container.setHolder(null);
    }

    this.getContainers = function() {
        return containers;
    }

    this.findContainerByIdentifier = function(identifier) {
        var found = null;

        containers.forEach(function(container) {
            if (container.getIdentifier() == identifier)
                found = container;
        });

        return found;
    }

    this.clear = function() {
        contentHolder.find("." + containerIdentifier).remove();
    }

    this.clearExcept = function(except) {
        contentHolder.find("." + containerIdentifier).not(except).remove();
    }

    this.getElement = function() {
        return elem;
    }

    this.setBuilder = function(b) {
        builder = b;
    }

    this.getBuilder = function() {
        return builder;
    }

    this.getContentSelector = function() {
        return contentSelector;
    }

    this.getContainerSelector = function() {
        return containerSelector;
    }

    this.setContainerSelector = function(cs) {
        containerSelector = cs;

        elem = $(containerSelector);
    }

    this.setContentSelector = function(cs) {
        contentSelector = cs;

        contentHolder = elem.find(contentSelector);
    }

    this.create = function() {
        elem = builder.build(self);

        updateTitle(elem);

        listeners.forEach(function(listener) {
            listener.onCreate(self);
        });
    }

    this.initialize = function() {
        listeners.forEach(function(listener) {
            listener.onInit(self);
        });

        elem.data("component", self);
    }

    var updateTitle = function(elem) {
        var titleElement = elem.find(".title");

        if (title == null) {
            titleElement.hide();
        } else {
            titleElement.show();
            titleElement.html("<strong>" + title + "</strong>");
        }
    }

    var __construct = function (that) {
        self = that;
        identifier = params.identifier;
        builder = params.builder;
        self.setContainerSelector(params.containerSelector);
        self.setContentSelector(params.contentSelector);

        if (typeof(params.actionEnabled) != 'undefined')
            actionEnabled = params.actionEnabled;

        if (typeof(params.actionLabel) != 'undefined')
            actionLabel = params.actionLabel;

        if (typeof(params.actionCustomClass) != 'undefined')
            actionCustomClass = params.actionCustomClass;

        if (typeof(params.actionLink) != 'undefined')
            actionLink = params.actionLink;

        if (typeof(params.actionExecutor) != 'undefined')
            actionExecutor = params.actionExecutor;
    }(this)
}

export function ViewHolderComponent(params) {
    var self = null;
    var identifier = null;
    var elem = null;
    var builder = null;

    var data = {};

    var containerSelector = null;
    var contentSelector = null;
    var contentHolder = null;
    var containerIdentifier = "holder_container";

    var actionEnabled = false;
    var actionLabel = "VER MAIS";
    var actionCustomClass = "";
    var actionExecutor = null;
    var actionLink = "";

    var listeners = [];
    var containers = [];

    this.getActionLink = function() {
        return actionLink;
    }

    this.getActionExecutor = function() {
        return actionExecutor;
    }

    this.getActionLabel = function() {
        return actionLabel;
    }

    this.getActionCustomClass = function() {
        return actionCustomClass;
    }

    this.getActionEnabled = function() {
        return actionEnabled;
    }

    this.getData = function() {
        return data;
    }

    this.setTitle = function(t) {
        self.getBuilder().setTitle(self, t);
    }

    this.getTitle = function() {
        return self.getBuilder().getParams().title;
    }

    this.getIdentifier = function() {
        return identifier;
    }

    this.setIdentifier = function(i) {
        identifier = i;
    }

    this.addListener = function(listener) {
        listeners.push(listener);
    }

    this.removeListener = function(listener) {
        listeners.removeItem(listener);
    }

    this.addContainer = function(container) {
        containers.push(container);

        contentHolder.append(container.getElement());
        container.getElement().addClass(containerIdentifier);
        container.setHolder(self);
    }

    this.removeContainer = function(container) {
        containers.removeItem(container);
        container.getElement().removeClass(containerIdentifier);

        container.setHolder(null);
    }

    this.clear = function() {
        contentHolder.find("." + containerIdentifier).remove();
    }

    this.clearExcept = function(except) {
        contentHolder.find("." + containerIdentifier).not(except).remove();
    }

    this.getElement = function() {
        return elem;
    }

    this.setBuilder = function(b) {
        builder = b;
    }

    this.getBuilder = function() {
        return builder;
    }

    this.getContentSelector = function() {
        return contentSelector;
    }

    this.getContainerSelector = function() {
        return containerSelector;
    }

    this.setContainerSelector = function(cs) {
        containerSelector = cs;

        elem = $(containerSelector);
    }

    this.setContentSelector = function(cs) {
        contentSelector = cs;

        contentHolder = elem.find(contentSelector);
    }

    this.create = function() {
        elem = builder.build(self);

        self.setContentSelector(contentSelector);

        containers.forEach(function(container){
            contentHolder.append(container.getElement());
        });

        listeners.forEach(function(listener) {
            listener.onCreateView(self);
        });
    }

    this.initialize = function() {
        listeners.forEach(function(listener) {
            listener.onInitView(self);
        });

        elem.data("component", self);
    }

    var facade = null;
    var level = 0;
    var focused = false;
    var opened = false;

    this.getLevel = function() {
        return level;
    }

    this.setLevel = function(l) {
        level = l;

        if (elem != null) {
            elem.addClass("level");
            elem.attr("data-level", "{level}".format({level: level}));
        }
    }

    this.setFocused = function(f) {
        focused = f;

        if (elem != null) {
            if (focused)
                elem.addClass("focused");
            else
                elem.removeClass("focused");
        }

        listeners.forEach(function(listener) {
            if (focused)
                listener.viewDidFocus(self);
            else
                listener.viewDidBlur(self);
        });
    }

    this.getOpened = function() {
        return opened;
    }

    this.getFocused = function() {
        return focused;
    }

    this.getFacade = function() {
        return facade;
    }

    this.open = function() {
        facade.getMechanic().push(self);

        var container = containers[0];

        if (!container.isAlreadyLoaded()) {
            facade.setLoading(true);
            setTimeout(function() { container.getProxy().execute(); }, 250);
        }
    }

    this.close = function() {
        facade.getMechanic().remove(self);
    }

    this.reload = function() {
        var container = containers[0];

        if (container.isAlreadyLoaded()) {
            facade.setLoading(true);
            setTimeout(function() { container.getProxy().execute(); }, 250);

        }
    }

    this.didAttachToWindow = function(wf) {
        facade = wf;

        builder.afterAttach(self);
    }

    this.didOpen = function(mechanic) {
        builder.animateIn(self);

        opened = true;

        listeners.forEach(function(listener) {
            listener.viewDidOpen(self);
        });
    }

    this.didClose = function(mechanic) {
        builder.animateOut(self);

        opened = false;

        listeners.forEach(function(listener) {
            listener.viewDidClose(self);
        });
    }

    var __construct = function (that) {
        self = that;
        identifier = params.identifier;
        builder = params.builder;
        self.setContainerSelector(params.containerSelector);
        self.setContentSelector(params.contentSelector);

        if (typeof(params.actionEnabled) != 'undefined')
            actionEnabled = params.actionEnabled;

        if (typeof(params.actionLabel) != 'undefined')
            actionLabel = params.actionLabel;

        if (typeof(params.actionCustomClass) != 'undefined')
            actionCustomClass = params.actionCustomClass;

        if (typeof(params.actionLink) != 'undefined')
            actionLink = params.actionLink;

        if (typeof(params.actionExecutor) != 'undefined')
            actionExecutor = params.actionExecutor;
    }(this)
}