export default function InputChangeObserver(ob, ip, cb) {
    let observer = $(ob);
    let inputs = $(ip);
    let callback = cb;

    let __construct = function (that) {
        inputs.change(function() {
            callback($(this));

            observer.removeClass("disabled");
        })

        inputs.trigger("change");

        observer.click(function() {
            $(this).addClass("disabled");
        });
    }(this)

    this.trigger = function() {
        inputs.trigger("change");
    };
}